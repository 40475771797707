import GridMixin from '@/views/components/grids/GridMixin';
import ProfileLink from '@/views/components/officials/ProfileLink.vue';

export default {
  mixins: [GridMixin],
  components: { ProfileLink },
  props: {
    category: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      officialsCategory: this.category.id,
      defaultColDef: null,
      columnDefs: null,
      gridOptions: {
        alwaysMultiSort: true,
      },
      rowData: [],
      paginationPageSize: 15,
      handleAffiliates: null,
      getRowStyle: null,
    };
  },
  async created() {
    const gridName = this.category.id;
    const {
      gridColumns,
      gridDefaultColDef,
      handleAffiliates,
      gridRowStyle,
    } = await import(`@/views/components/grids/officials/${gridName}`);
    this.defaultColDef = gridDefaultColDef;
    this.columnDefs = gridColumns('ProfileLink');
    this.handleAffiliates = handleAffiliates;
    this.getRowStyle = gridRowStyle ?? undefined;
  },
  methods: {
    setAffiliates(affiliates) {
      if (this.handleAffiliates) {
        this.rowData = this.handleAffiliates(affiliates);
        return;
      }

      // Generate rows for each affiliate positions
      const collection = [];

      affiliates.forEach((a) => {
        a.positions.forEach((p) => {
          const clonedA = JSON.parse(JSON.stringify(a));
          clonedA.positions = [p];
          collection.push(clonedA);
        });
      });

      this.rowData = collection;
    },
  },
};
