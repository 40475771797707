<template>
  <div v-if="affiliate">
    <a v-if="canBeLinked" href="javascript:" @click="goToProfile">
      {{ affiliate?.complete_name + (affiliate?.is_deceased ? ` ✝` : '') ?? '' }}
    </a>
    <span v-else>
      {{ affiliate?.complete_name + (affiliate?.is_deceased ? ` ✝` : '') ?? '' }}
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    goToProfile() {
      this.$router.push(`/affiliates/${this.affiliate?.id}`);
    },
  },
  computed: {
    affiliate() {
      return this.params.getValue();
    },
    canBeLinked() {
        return this.affiliate.is_approved && this.affiliate.is_name_visible;
    },
  },
};
</script>
