import BaseMixin from '@/views/components/officials/mixins/Base';
import OfficialsPublic from '@/services/Api/Public/Officials';
import { get as getByPath } from 'lodash';

export default {
  mixins: [BaseMixin],
  data() {
    return {
      initialItems: [],
      items: [],
      itemId: '',
    };
  },
  async mounted() {
    await this.fetchItems();

    // Get itemId value from query string
    const queryField = this.category.selector_field ?? 'position_id';
    const queryValue = this.$route.query[queryField];

    // Set itemId value
    if (queryValue) {
      let itemId = parseInt(queryValue, 10);

      if (Number.isNaN(itemId)) {
        itemId = queryValue;
      }

      this.itemId = itemId;
    }
  },
  methods: {
    async fetchItems() {
      const data = await OfficialsPublic.selectors(this.category.id);
      this.initialItems = data.data.data;

      this.items = this.initialItems.map((item) => {
          if (this.category.id === 'ifac-officials') {
            return {
              value: item.code,
              text: item.name,
            };
          }

          return {
            value: item.id,
            text: item.name,
          };
      });
    },
    async fetchAffiliates() {
      this.isLoading = true;
      const data = await OfficialsPublic.affiliates(
        this.category.id,
        {
          [this.category.selector_field ?? 'position_id']: this.itemId,
        },
      );
      this.setAffiliates(data.data.data);
      this.isLoading = false;
    },
  },
  computed: {
    selectedItem() {
      return this.items.find((c) => c.value === this.itemId);
    },
    initialSelectedItem() {
      return this.initialItems.find((i) => i.id === this.itemId);
    },
    selectedTitle() {
      let selectedItemText = this.selectedItem.text;

      if (this.category?.subtitle_selected) {
        const text = getByPath(this.initialSelectedItem, this.category?.subtitle_selected);
        selectedItemText += ` ${ text}`;
      }

      if (this.category?.subtitle_position === 'left') {
        return `${this.category.subtitle} ${selectedItemText}`;
      }

      if (this.category?.subtitle_position === 'right') {
        return `${selectedItemText} ${this.category.subtitle}`;
      }

      return selectedItemText;
    },
    hasIfacRestricted() {
      return this.rowData.filter((a) => a.is_name_visible === false).length > 0;
    },
  },
  watch: {
    itemId(newValue) {
      const query = {
        category: this.$route.query.category,
        [this.category.selector_field ?? 'position_id']: newValue,
      };

      this.$router
        .push({ path: '/affiliates/officials', query })
        .catch(() => {
          // skip duplicated warning
        });

      this.fetchAffiliates();
    },
  },
};
