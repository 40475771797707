<template>
  <div class="p-4">
    <div>
      <b-card-text>{{ category.title }}</b-card-text>
      <b-form-select class="form-control" v-model="itemId" :options="items" size="sm"/>
    </div>

    <div v-if="selectedItem">
      <h3 class="mt-4">
        {{ selectedTitle }}
      </h3>

      <b-alert variant="warning" :show="hasIfacRestricted">
        You may login to see the IFAC Restricted names
      </b-alert>

      <b-alert class="mb-0" variant="warning" :show="!isLoading && rowData.length === 0">
        No public IFAC official in this category at this time
      </b-alert>

      <ag-grid-vue
        v-show="rowData.length > 0"
        class="ag-theme-alpine"
        :pagination="true"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :gridOptions="gridOptions"
        domLayout="autoHeight"
        :paginationPageSize="paginationPageSize"
      ></ag-grid-vue>
    </div>
  </div>
</template>

<script>
import AffiliatesWithSelector from '@/views/components/officials/mixins/AffiliatesWithSelector';

export default {
  mixins: [AffiliatesWithSelector],
};
</script>
